import { App, Ref, isRef } from 'vue'

export default {
  install: (app: App<any>) => {
    const scrollToBottom = (
      elementRef: Ref<HTMLElement> | HTMLElement,
      timeout = 200,
      behavior: 'instant' | 'smooth' = 'instant',
    ) => {
      setTimeout(() => {
        const element = isRef(elementRef) ? elementRef.value : elementRef
        const height = element.scrollHeight
        element.scrollTo({
          left: 0,
          top: height,
          behavior,
        })
      }, timeout)
    }

    app.provide('scrollToBottom', scrollToBottom)
    app.config.globalProperties.$scrollToBottom = scrollToBottom
  },
}
